body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.img-round {
  border-radius: 99px;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  -ms-border-radius: 99px;
  -o-border-radius: 99px;
}

.main {
  display: flex;
  justify-content: space-around;
  a {
    color: #546E7A;
    display: block;
    width: 100%;
    font-size: 16px;
    word-break: break-all;
  }
  a:hover {
    text-decoration: none;
    color: rgba(178,27,204, .9);
  }
}

.mt-0 {
  margin-top: 0px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-30 {
  margin-bottom: 30px;
}

.glow-title {
  color: rgba(178,27,204, .9);
  font-size: 18px;
}

.section-title {
  margin-bottom: 44px;
  background-color: rgba(128,140,152,.1);
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

@media (max-width: 768px) {
  .main {
    display: flex;
    flex-direction: column;
  }
  .section-title {
   h2 {
     font-size: 24px;
   }
  }
}
