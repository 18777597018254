.sidebar {
    padding: 15px;
    background-color: rgba(128,140,152,.1);
    max-width: 300px;
    min-height: 100vh;
    .division {
        background-color: rgba(178,27,204,.4);
        height: 3px;
        width: 90%;
        margin: 5px 0px 5px 0px;
    }
    .sidebar-header {
        display: flex;
        align-items: center;
        justify-content: top;
        flex-direction: column;
        text-align: center;
        position: sticky;
        top: 15px;
        .img-profile {
            max-width: 100px;
        }
        h1 {
            font-size: 20px;
            margin-top: 10px;
            color: #546E7A;
        }
        h2 {
            font-size: 16px;
            color: #546E7A;
            font-weight: 900;
        }
    }
}

@media (max-width: 768px) {
    .sidebar {
      max-width: 100%;
      min-height: 100%;
    }
}
  